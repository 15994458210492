import React from 'react';
import { RoutePath, RoutesProvider } from '@shared/core/router.helper';
import loadable from '@loadable/component';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import { Redirect, RouteComponentProps } from '@react-router';
import { useQueryParamHelper } from '@shared/core/queryString';
import DashboardPreview from '@apps/admin/routes/DashboardPreview';
import { ProtectedAdminRoute } from '@apps/admin/components/ProtectedAdminRoute';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { Suspense } from '@shared/core/react/Suspense';
import { SERVICE_CENTER_APP_NAME } from '@apps/serviceCenter/constants';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE } from '@apps/saveTheDate/constants';
import { getEcardType } from '@apps/saveTheDate/SaveTheDate.utils';
import EcardError from '@shared/components/EmailsAndEcards/components/Error';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { CREATE_BABY_REGISTRY_APP_NAME } from '@apps/createBabyRegistry/constants';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { EventRoute } from './EventRoute';
import { getConfig } from '@config';
import { CardsRoutesProvider } from '@apps/card/Card.routes';

const isDev = getConfig().environment === 'dev';

const PlaygroundApp = loadable(
  () =>
    import(
      /* webpackChunkName: "content/playground" */
      '@apps/playground'
    )
);

const Admin = loadable(
  () =>
    import(
      /* webpackChunkName: "content/admin" */
      '@apps/admin'
    )
);

const AdminBookmarklet = loadable(
  () =>
    import(
      /* webpackChunkName: "registry/admin/routes/Bookmarklet" */
      '@apps/registry/admin/routes/Bookmarklet'
    )
);

const AccountApp = loadable(
  () =>
    import(
      /* webpackChunkName: "content/account" */
      '@apps/account'
    )
);

// const GuestShowcaseRoute = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "content/showcase" */
//       '@apps/showcase'
//     )
// );

const GuestRegistryRedirect = loadable(
  () =>
    import(
      /* webpackChunkName: "content/registry/guest/retailer-redirect" */
      '@apps/registry/guest/routes/RedirectToRetailer'
    )
);

const RegistryGuest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/registry-guest" */
      '@apps/registry/guest'
    )
);

const RegistryBookmarkletPopupTrigger = loadable(
  () =>
    import(
      /* webpackChunkName: "content/registry-popuptrigger" */
      '@apps/registry/popup-trigger'
    )
);

const AccountMe = loadable(
  () =>
    import(
      /* webpackChunkName: "content/account/me" */
      '@apps/accountMe'
    )
);

const PublicShop = loadable(
  () =>
    import(
      /* webpackChunkName: "content/publicshopcsr" */
      '@apps/registry/public/routes/Shop/Shop'
    )
);

const CreateWedding = loadable(
  () =>
    import(
      /* webpackChunkName: "content/createwedding" */
      '@apps/createWedding'
    )
);

const CreateBabyRegistry = loadable(
  () =>
    import(
      /* webpackChunkName: "content/createbabyregistry" */
      '@apps/createBabyRegistry'
    )
);

const EcardGuest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/ecard/guest" */
      '@apps/ecard/guest'
    )
);

const ServiceCenter = loadable(
  () =>
    import(
      /* webpackChunkName: "content/servicecenter" */
      '@apps/serviceCenter'
    )
);

const Guest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/servicecenter" */
      '@apps/guest'
    )
);

const SaveTheDateGuest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/saveTheDate/guest" */
      '@apps/saveTheDate/guest'
    )
);

const EmailPreferences = loadable(
  () =>
    import(
      /* webpackChunkName: "content/emailpreferences" */
      '@apps/emailPreferences'
    )
);

const ExampleConfigUsage = loadable(() => import('@apps/examples/config-usage'));

const ExampleFeatureFlagUsage = loadable(() => import('@apps/examples/feature-flag-usage'));

const ThanksForRsvpApp = loadable(
  () =>
    import(
      /* webpackChunkName: "content/thanksForRsvp" */
      '@apps/thanksForRsvp'
    )
);

const GreetingCard = loadable(
  () =>
    import(
      /* webpackChunkName: "content/greetingCard" */
      '@apps/greetingCard'
    )
);

const EventRedirect = loadable(
  () =>
    import(
      /* webpackChunkName: "content/eventredirect" */
      '@apps/admin/routes/EventRedirect'
    )
);

const PublicStationeryGallery = loadable(
  () =>
    import(
      /* webpackChunkName: "content/publicstationeryshop" */
      '@apps/card/PublicShop'
    )
);

const RecipientPremiumEcardPreview = loadable(
  () =>
    /* webpackChunkName: "content/card/recipientpremiumecardpreview" */
    import('@apps/card/routes/RecipientPremiumEcardPreview')
);

const PublicStaysApp = loadable(
  () =>
    import(
      /* webpackChunkName: "content/hotels" */
      '@apps/stays/PublicStays'
    )
);

const RootGuestSiteRoute: React.FC<RouteComponentProps<{ eventHandle: string }>> = ({ match }) => {
  const { getValueString } = useQueryParamHelper();

  if (match) {
    if (getValueString('preview') === 'true' && getValueString('ctx') === 'admindashboard') {
      // Now that we're rendering the entire admin dashboard preview panel in joy-web,
      // we leverage the 'ctx' query param in rendering
      const postMessageShareClicked = () => {
        sendMessageToParentWindow({ action: 'previewActionInteraction', value: 'shareButton', source: 'joyPreview' });
      };
      return (
        <Suspense fallback={<></>}>
          <ProtectedAdminRoute
            disableDefaultLoader={true}
            isNonEditAdminRoute={true}
            path={`/${match.params.eventHandle}`}
            loader={<></>}
            render={() => {
              return <DashboardPreview isSitePreview eventHandle={match.params.eventHandle} handleShareDialogOpen={postMessageShareClicked} />;
            }}
          />
        </Suspense>
      );
    }

    // We are handling redirection here: https://github.com/joylifeinc/joy-web/blob/b22185084f3a63796d37f0f14d44df6e7a235fe8/src/apps/guest/routes/GuestSite/GuestSite.utils.ts#L82
    // window.location.href = window.location.origin + `/${match.params.eventHandle}/edit`;

    return <Guest />;
  }

  // Redirect to root route
  window.location.href = window.location.origin;
  return null;
};

const SeptemberInvestorDemoPrintTeam = () => {
  // Disabling the feature value check because it's triggering a re-render loop -- which is not ideal
  // user is redirected back after account creation. It was causing multiple events to be created.

  // const isEnabled = useFeatureValue('printPublicShopEnabled').value === 'on';
  return <PublicStationeryGallery />; // isEnabled ? <PublicStationeryGallery /> : null;
};

export const routes: Array<RoutePath<{ eventHandle: string; ecardId: string }>> = [
  {
    path: '/:eventHandle/registry/bookmarklet/popup-trigger',
    component: () => <RegistryBookmarkletPopupTrigger />
  },
  {
    path: '/account/(events|personal-info)',
    component: () => <AccountMe appUrl={'account'} />
  },
  {
    path: '/account',
    component: () => <AccountApp appUrl="account" />
  },
  {
    path: '/account-callback',
    component: () => <AccountApp appUrl="account-callback" />
  },
  {
    path: '/social-login',
    component: () => <AccountApp appUrl="social-login" />
  },
  {
    path: `/${CREATE_WEDDING_APP_NAME}`,
    component: () => <CreateWedding />
  },
  {
    path: `/${CREATE_BABY_REGISTRY_APP_NAME}`,
    component: () => <CreateBabyRegistry />
  },
  {
    path: '/ecard',
    component: ({ location }) => {
      const ecardType = getEcardType(location);
      return ecardType === undefined ? <EcardError /> : ecardType === SAVE_THE_DATE_PHOTO_ECARD_TYPE ? <SaveTheDateGuest /> : <EcardGuest />;
    }
  },
  {
    path: `/email-preferences`,
    component: () => <EmailPreferences />
  },
  // Public shop is generally SSR, but we can also serve it CSR when using the `feature.joy-web` FF.
  {
    path: '/shop',
    component: () => (
      <RoutesProvider>
        <PublicShop />
      </RoutesProvider>
    )
  },
  {
    path: '/notfound',
    component: () => <PageNotFound />
  },
  {
    path: `/${SERVICE_CENTER_APP_NAME}`, //other subroutes?
    component: () => <ServiceCenter />
  },
  {
    path: '/examples/config-usage',
    component: () => (isDev ? <ExampleConfigUsage /> : <Redirect to="/" />)
  },
  {
    path: '/examples/feature-flag-usage',
    component: () => (isDev ? <ExampleFeatureFlagUsage /> : <Redirect to="/" />)
  },
  {
    path: '/card/:ecardId',
    component: ({ match }) =>
      match ? (
        match.params.ecardId.length === 10 ? (
          <CardsRoutesProvider>
            <RecipientPremiumEcardPreview orderNumber={match.params.ecardId} />
          </CardsRoutesProvider>
        ) : (
          <GreetingCard ecardId={match.params.ecardId} />
        )
      ) : null
  },
  // always put eventHandle routes last to allow above routes to match
  // {
  //   path: '/:eventHandle/showcase',
  //   component: ({ match }) => (match ? <GuestShowcaseRoute eventHandle={match.params.eventHandle} /> : null)
  // },
  {
    path: '/cards',
    component: () => <SeptemberInvestorDemoPrintTeam />
  },
  {
    path: '/card',
    component: ({ location }) => {
      return <Redirect to={{ pathname: '/cards', search: location.search }} />;
    }
  },
  {
    path: '/travel',
    component: () => <PublicStaysApp />
  },
  {
    path: '/event',
    component: () => <EventRedirect />
  },
  {
    path: '/:eventHandle/thanksForRsvp',
    component: ({ match }) => <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={ThanksForRsvpApp} />
  },
  {
    path: '/:eventHandle/playground',
    component: ({ match }) => (!getNodeEnv().isProduction ? <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={PlaygroundApp} /> : null)
  },
  {
    path: '/:eventHandle/registry/retailer-redirect',
    component: ({ match }) => <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={GuestRegistryRedirect} />
  },
  {
    path: '/:eventHandle/registry',
    component: ({ match }) => <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={RegistryGuest} />
  },
  {
    path: '/:eventHandle/edit/registry/bookmarklet',
    component: ({ match }) => <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={AdminBookmarklet} />
  },
  {
    path: '/:eventHandle/edit',
    component: ({ match }) => <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={Admin} />
  },
  {
    path: '/:eventHandle',
    component: props => (
      <EventRoute eventHandle={props.match.params.eventHandle}>
        <RootGuestSiteRoute {...props} />
      </EventRoute>
    )
  }
];
